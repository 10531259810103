<template>
	<div class="all-rule-list">
		<el-form
			:inline="true"
			class="form-content"
			:model="dataForm"
			@keyup.enter.native="getDataList()"
		>
			<el-form-item>
				<el-input style="width: 300px;" v-model="name" placeholder="输入团队名称关键字查询" clearable>
					<el-button slot="append" icon="el-icon-search" @click="getDataList()"></el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<!-- 	<el-button type="primary" @click="dialogVisible=true" >添加</el-button> -->
				<!-- 			<el-button type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" >删除</el-button> -->
			</el-form-item>
		</el-form>
		<el-table :data="dataList" v-loading="dataListLoading" @selection-change="selectionChangeHandle">
			<el-table-column prop="teamsName" min-width="280" label="工作室名称"></el-table-column>
			<el-table-column prop="entityName" min-width="200" label="管理病种"></el-table-column>
			<el-table-column prop="hospitalName" min-width="200" label="所属医院"></el-table-column>
			<el-table-column prop="createTime" min-width="180" label="创建时间"></el-table-column>
			<el-table-column header-align="right" align="right" min-width="400" label="操作">
				<template slot-scope="scope">
					<el-button
						style="color: #A767FD;"
						type="text"
						size="small"
						@click="showShareDialog2(scope.row)"
					>邀请医生二维码</el-button>
					<el-button
						style="color: #A767FD;"
						type="text"
						size="small"
						@click="showShareDialog(scope.row)"
					>邀请患者二维码</el-button>
					<!-- 			<el-button style="color: #A767FD;" type="text" size="small" @click="gotoEdit(scope.row)" >编辑</el-button> -->
					<el-button
						style="color: #A767FD;"
						type="text"
						size="small"
						@click="gotoMemberEdit(scope.row)"
					>成员管理</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@size-change="sizeChangeHandle"
			@current-change="currentChangeHandle"
			:current-page="pageIndex"
			:page-sizes="[10, 20, 50, 100]"
			:page-size="pageSize"
			:total="totalPage"
			layout="total, sizes, prev, pager, next, jumper"
		></el-pagination>
		<el-dialog title="添加团队" :visible.sync="dialogVisible" width="500px">
			<el-form label-width="100px">
				<el-form-item :inline="true" label="团队名称" required>
					<el-input style="width:300px" v-model="teamsName" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="管理病种" required>
					<el-select style="width:300px" v-model="entityId" filterable>
						<el-option v-for="item in jcbzList" :key="item.value" :value="item.value" :label="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="团队介绍">
					<el-input
						:rows="4"
						style="width:300px"
						type="textarea"
						maxlength="90"
						v-model="content"
						placeholder="请输入"
					></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addTeam" v-points>确定</el-button>
				<el-button @click="dialogVisible = false">取消</el-button>
			</div>
		</el-dialog>
		<el-dialog title="编辑团队" :visible.sync="dialogVisible2" width="500px">
			<el-form label-width="100px">
				<el-form-item :inline="true" label="团队名称" required>
					<el-input style="width:300px" v-model="dataForm.teamsName" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="管理病种" required>
					<el-select style="width:300px" v-model="dataForm.entityId" filterable>
						<el-option v-for="item in jcbzList" :key="item.value" :value="item.value" :label="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="团队介绍">
					<el-input
						:rows="4"
						style="width:300px"
						type="textarea"
						maxlength="90"
						v-model="dataForm.content"
						placeholder="请输入"
					></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="editTeam" v-points>确定</el-button>
				<el-button @click="dialogVisible2 = false">取消</el-button>
			</div>
		</el-dialog>
		<el-dialog
			title="邀请二维码"
			:close-on-click-modal="false"
			:visible.sync="shareDialog"
			:before-close="handleClose"
			width="250px"
		>
			<div class="share-box">
				<div class="code-img">
					<div class="qrcode" id="qrCode" ref="qrCodeDiv"></div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Qs from "qs";
import QRCode from "qrcodejs2";
export default {
  components: {
    // AddOrUpdate
  },

  data() {
    return {
      teamsName: "",
      entityId: "",
      content: "",
      jcbzList: [],
      dataForm: {
        teamsName: "",
        entityId: "",
        content: "",
      },
      name: "",
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      shareDialog: false,
      dialogVisible: false,
      dialogVisible2: false,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },

  computed: {},

  created() {
    this.getDataList();
    this.getJcbzList();
  },

  methods: {
    showShareDialog(row) {
      this.shareDialog = true;
      //正式
      // this.url =
      //  this.$global.dominUrl2 +
      //   "/patient/#/login?teamsId=" +
      //   row.id +
      //   "&doctorId=" +
      //   JSON.parse(localStorage.userInfo).id;
 //测试
 this.url =
  this.$global.dominUrl2 +
   "/devpatient/#/login?teamsId=" +
   row.id +
   "&doctorId=" +
   JSON.parse(localStorage.userInfo).id;
      this.$nextTick(function() {
        this.bindQRCode();
      });
    },
    showShareDialog2(row) {
      this.shareDialog = true;
      this.url =
       this.$global.dominUrl2 +
        "devdoctor/#/login?teamsId=" +
        row.id +
        "&entityId=" +
        row.entityId;

      this.$nextTick(function() {
        this.bindQRCode();
      });
    },
    bindQRCode() {
      //获取二维码
      let that = this;
      that.qrcode = new QRCode("qrCode", {
        text: that.url,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    handleClose() {
      this.shareDialog = false;
      document.getElementById("qrCode").innerHTML = "";
    },
    gotoEdit(row) {
      this.dataForm = row;
      this.dataForm.entityId = parseInt(this.dataForm.entityId);
      this.dialogVisible2 = true;
    },
    // 获取数据列表
    getDataList() {
      // let deptId = JSON.parse(localStorage.getItem("userInfo")).deptId
      let obj = {
        pageNo: this.pageIndex,
        pageSize: this.pageSize,
        teamsName: this.name,
        hospitalId: JSON.parse(localStorage.userInfo).deptPid,
      };
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl("/teamsManage/list"),
        method: "get",
        params: obj,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.dataList = data.data;
        this.totalPage = data.totalCount;

        this.dataListLoading = false;
      });
    },
    //基础病种
    getJcbzList() {
      let deptId = this.$cookie.get("hospitalId");
      // let deptId = JSON.parse(localStorage.getItem("userInfo")).deptId
      let obj = {
        pageNo: 1,
        pageSize: 9999,
        type: "基础病种",
      };
      this.dataListLoading = true;
      this.$http({
        url: this.$http.adornUrl("/dict/list"),
        method: "get",
        params: obj,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.jcbzList = data.data;
      });
    },
    addTeam() {
      var that = this;
      var params = {
        teamsName: this.teamsName,
        content: this.content,
        hospitalId: JSON.parse(localStorage.userInfo).deptPid,
        deptId: JSON.parse(localStorage.userInfo).deptId,
        entityId: this.entityId,
      };
      that
        .$http({
          url: that.$http.adornUrl("/teamsManage/add"),
          method: "post",
          params: params,
        })
        .then(({ data }) => {
          if (data && data.status) {
            that.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                that.dialogVisible = false;
                that.content = "";
                that.teamsName = "";
                that.entityId = "";
                that.getDataList();
              },
            });
          } else {
            that.$message.error(data.msg);
          }
        });
    },
    editTeam() {
      var that = this;

      that
        .$http({
          url: that.$http.adornUrl("/teamsManage/update"),
          method: "post",
          params: that.dataForm,
        })
        .then(({ data }) => {
          if (data && data.status) {
            that.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                that.dialogVisible2 = false;

                that.getDataList();
              },
            });
          } else {
            that.$message.error(data.msg);
          }
        });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },

    gotoRoleInfo(info) {
      this.$router.push({
        path: "/role/role-info",
        query: { id: info.id },
      });
    },
    gotoMemberEdit(info) {
      this.$router.push({
        path: "/team/memberAdd",
        query: {
          id: info.id,
          teamsName: info.teamsName,
          entityId: info.entityId,
        },
      });
      // this.$router.push({
      // 	path: 'team/memberList',
      // 	query: {
      // 		id: info.id,
      // 		teamsName:info.teamsName,
      // 		entityId:info.entityId,
      // 	}
      // })
    },

    // 删除
    deleteHandle(id) {
      var userIds = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      var ids = {
        userId: this.$cookie.get("userId"),
        ids: userIds.join(","),
      };

      this.$confirm(`确定进行[${id ? "删除" : "批量删除"}]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/teamsManage/delete"),
            method: "post",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",

              token: this.$cookie.get("token"),
            },
            data: Qs.stringify(ids),
          }).then(({ data }) => {
            if (data.status) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                },
              });
            } else {
              this.$message.error(data.msg);
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.all-rule-list {
  .search-top {
    padding-bottom: 20px;
    overflow: hidden;

    .search-btn {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .el-dialog {
    width: 600px;
  }
}
.share-box {
  line-height: 3em;
}
.copy-box {
  display: flex;
  justify-content: space-between;
  .qrcode {
    width: 200px;
    height: 200px;
  }
}
</style>